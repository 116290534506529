import React, { Component } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import imgTick from "../images/gfx/tick.png"
import imgHero from "../images/gfx/hero-complete-package.jpg"
import imgMobileHero from "../images/gfx/mobile-hero-complete-package.jpg"
import "./index.css";
import "./complete-package.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Contact from "../components/contact"
import Nav from "../components/nav"
import Footer from "../components/footer"

class IndexPage extends Component {

  constructor(props) {
    super(props);

    this.state = {
      activeTab: 'sell',
      activeFaq: null
    };
  }

  componentDidMount(){
    AOS.init({
      duration : 2000
    });
  }

  componentDidUpdate() {
    AOS.refresh();
  }

  showTab(tab) {
    this.setState({
      activeTab: this.state.activeTab === tab ? null : tab
    });
  }

  showFaq(tab) {
    this.setState({
      activeFaq: this.state.activeFaq === tab ? null : tab
    });
  }

  render() {
    return (
      <Layout>
        <SEO title="Bus pass and bus ticket scanning | Sell bus passes online | Complete package" description="Simplify your home to school services with ShuttleID. Payments, ticket distribution and scanning using a simple but cost-effective approach." />
        <Nav activeTab="packages" />
        <div>
            <div>

              <div className="hero-complete-package" style={{ backgroundImage: `url(${imgHero})`, backgroundColor: '#063a70', backgroundSize: '100% auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'left center' }}>
                <h2>Complete Package</h2>
                <h3>Sell bus passes and tickets 24/7 that are delivered instantly. For efficient operators that work smarter not harder.</h3>
                <div className="hero__links">
                  <Link to="/book-demo/" className="btn">Book a demo</Link>
                </div>
              </div>

              <div className="mobile-hero-complete-package" style={{ backgroundImage: `url(${imgMobileHero})`, backgroundColor: '#084e96', backgroundSize: '100% auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'center top' }}>
                <h2>Complete Package</h2>
                <h3>Sell bus passes and tickets 24/7 that are delivered instantly. For efficient operators that work smarter not harder.</h3>
                <div className="hero__links">
                  <Link to="/book-demo/" className="btn">Book a demo</Link>
                </div>
              </div>

              <div className='package-page'>

                <div className="package-page__main">

                  {/* <h3>Benefits</h3>
                  <table className="benefits">
                    <tbody>
                      <tr>
                        <td className="benefits__icon"><img className="tick" src={imgTick} alt="" /></td>
                        <td>Take payments for tickets and simple renewals 24/7. Funds to you, fast!</td>
                      </tr>
                      <tr>
                        <td className="benefits__icon"><img className="tick" src={imgTick} alt="" /></td>
                        <td>Easy-to-use passenger portal that needs little operator admin</td>
                      </tr>
                      <tr>
                        <td className="benefits__icon"><img className="tick" src={imgTick} alt="" /></td>
                        <td>Convenient cashless system that’s safer and simpler for everyone</td>
                      </tr>
                      <tr>
                        <td className="benefits__icon"><img className="tick" src={imgTick} alt="" /></td>
                        <td>You retain total control of ticket sales, route capacity and price</td>
                      </tr>
                      <tr>
                        <td className="benefits__icon"><img className="tick" src={imgTick} alt="" /></td>
                        <td>Impressive data to help you plan and grow your business</td>
                      </tr>
                    </tbody>
                  </table> */}

                  <h3>How it works</h3>
                  <p>Our Complete Package can help you sell, issue, scan and manage tickets for home-to-school travel. Packed with simple features that make a huge difference to your admin time and bottom line. Need live vehicle tracking, real-time passenger data and boarding notifications? Check out our new <Link className="link" to="/school-bus-tracking-system-app/">LIVE add-on</Link>. The Complete Package is backed by a <Link className="link" style={{ fontWeight: 'bold' }} to="/money-back-guarantee/">100-day money back guarantee</Link>.</p>

                  <div className='package__how-it-works'>
                    <div className={`${this.state.activeTab === 'sell' ? 'active' : ''} package__how-it-works__item`}>
                      <div className="package__how-it-works__head" onClick={() => { this.showTab('sell')}}>
                        <span>1. Sell your tickets</span>
                        <span className="package__how-it-works__head-symbol">
                          {this.state.activeTab === 'sell' && (<>-</>)}
                          {this.state.activeTab !== 'sell' && (<>+</>)}
                        </span>
                      </div>
                      <div className="package__how-it-works__body">
                        <p className="package__how-it-works__highlight">Use the Passenger Portal to sell tickets 24/7</p>
                        <ul>
                          <li>List tickets for sale. Manage capacity to avoid overallocation issues</li>
                          <li>Offer multiple payment methods including card and monthly direct debit</li>
                          <li>Boost sales by selling discounted tickets privately (e.g. AM/PM only)</li>
                          <li>Give extra flexibility with single, return, week, 4-week and flexi tickets</li>
                          <li>Promote your ticket listing through your website, email and social media</li>
                          <li style={{ margin: 0 }}>Parents buy tickets any time of day - when it's convenient for them</li>
                        </ul>
                      </div>
                      {/* <button className="package__how-it-works__next-btn btn" onClick={() => { this.showTab('issue')}}>
                        <span>Next: Issue tickets </span><img src={imgArrowDown} alt="" />
                      </button> */}
                    </div>


                    <div className={`${this.state.activeTab === 'issue' ? 'active' : ''} package__how-it-works__item`}>
                      <div className="package__how-it-works__head" onClick={() => { this.showTab('issue')}}>
                        <span>2. Issue your tickets</span>
                        <span className="package__how-it-works__head-symbol">
                          {this.state.activeTab === 'issue' && (<>-</>)}
                          {this.state.activeTab !== 'issue' && (<>+</>)}
                        </span>
                      </div>
                      <div className="package__how-it-works__body">
                        <p className="package__how-it-works__highlight">Tickets that distribute themselves</p>
                        <ul>
                          <li>Tickets are automatically issued by the platform after purchase</li>
                          <li>Parents instantly print off or forward their ticket to passenger's mobile device</li>
                          <li>24/7-access to tickets means no more lost or "forgotten" passes</li>
                          <li style={{ margin: 0 }}>Scanner technology ensures tickets cannot be shared</li>
                        </ul>
                      </div>
                      {/* <button className="package__how-it-works__next-btn btn" onClick={() => { this.showTab('scan')}}>
                        <span>Next: Scan tickets </span><img src={imgArrowDown} alt="" />
                      </button> */}
                    </div>

                    <div className={`${this.state.activeTab === 'scan' ? 'active' : ''} package__how-it-works__item`}>
                      <div className="package__how-it-works__head" onClick={() => { this.showTab('scan')}}>
                        <span>3. Scan your tickets</span>
                        <span className="package__how-it-works__head-symbol">
                          {this.state.activeTab === 'scan' && (<>-</>)}
                          {this.state.activeTab !== 'scan' && (<>+</>)}
                        </span>
                      </div>
                      <div className="package__how-it-works__body">
                        <p className="package__how-it-works__highlight">Check passes quickly and accurately with the Scanner</p>
                        <ul>
                          <li>Tablet device is mounted using a flexible stand but not hardwired</li>
                          <li>Passengers self-scan their mobile or printed ticket</li>
                          <li>Alerts driver to duplicate tickets, expired tickets, voided tickets and more</li>
                          <li>Driver only needs to intervene when a ticket is flagged</li>
                          <li style={{ margin: 0 }}>Scan logs keep a record of who boarded and when for your operations team</li>
                        </ul>
                      </div>
                      {/* <button className="package__how-it-works__next-btn btn" onClick={() => { this.showTab('manage')}}>
                        <span>Next: Manage </span><img src={imgArrowDown} alt="" />
                      </button> */}
                    </div>

                    <div className={`${this.state.activeTab === 'manage' ? 'active' : ''} package__how-it-works__item`}>
                      <div className="package__how-it-works__head" onClick={() => { this.showTab('manage')}}>
                        <span>4. Manage</span>
                        <span className="package__how-it-works__head-symbol">
                          {this.state.activeTab === 'manage' && (<>-</>)}
                          {this.state.activeTab !== 'manage' && (<>+</>)}
                        </span>
                      </div>
                      <div className="package__how-it-works__body">
                        <p className="package__how-it-works__highlight">Manage everything from the Operations Portal</p>
                        <ul>
                          <li>Scan summary alerts you to problems across your fleet at a glance</li>
                          <li>Examine detailed scan logs for a service or an individual passenger</li>
                          <li>View detailed passenger data including parent contact info</li>
                          <li>Move passengers between services to maximise capacity</li>
                          <li>Renewal system simplifies and speeds up handling the bulk of your sales</li>
                          <li>Create new services or release more tickets as demand dictates</li>
                          <li>Send SMS service alerts to ticket holders</li>
                          <li style={{ margin: 0 }}>Void tickets instantly without the hassle of trying to collect passes back</li>
                        </ul>
                      </div>
                      <Link to="/contact/" className="package__how-it-works__next-btn btn">
                        <span>Contact us to find out more</span>
                      </Link>
                    </div>

                  </div>

                  <div className="package__more-info">
                    <h4>Have further questions?</h4>
                    <ul>
                      <li>Call our friendly specialist team on 0333 344 9868</li>
                      <li>Complete our enquiry form for more details and pricing</li>
                      <li>You might find your answer in our FAQ section below</li>
                    </ul>
                  </div>

                </div>

                <div className="package-page__pricing">
                  <div className="package-page__pricing-info">
                    <h3 id="pricing">Pricing</h3>
                    <table>
                      <tbody>
                        <tr>
                          <th style={{ width: '10%' }}>Setup</th>
                          <td style={{ width: '90%', textAlign: 'left' }}>FREE</td>
                        </tr>
                        <tr>
                          <th>Hardware</th>
                          <td style={{ textAlign: 'left' }}>From £50</td>
                        </tr>
                        <tr>
                          <th>Platform</th>
                          <td style={{ textAlign: 'left' }}>2.5% per transaction + payment processor fee<br /><br />Discounts available on high value purchases</td>
                        </tr>
                      </tbody>
                    </table>
                    <p className="highlight no-margin">Complete the enquiry form below for more details and accurate pricing based on your requirements:</p>
                  </div>

                  <form style={{ textAlign: 'left' }} action="/complete-package-pricing-requested/" id="pricing-complete" name="pricing-complete-package" method="POST" className="package-page__pricing-form" data-netlify="true" data-netlify-honeypot="bot-field">

                    <p style={{ display: 'none' }}>
                      <label>Don't fill this out if you're human: <input name="bot-field" /></label>
                    </p>
                    <div className="package-page__pricing-form__group">
                      <label>Your name *</label>
                      <input placeholder="Your name *" name="name" required className="package-page__pricing-form__input" />
                    </div>
                    <div className="package-page__pricing-form__group">
                      <label>Company *</label>
                      <input placeholder="Company *" name="company" required className="package-page__pricing-form__input" />
                    </div>
                    <div className="package-page__pricing-form__group">
                      <label>Email *</label>
                      <input placeholder="Email *" name="email" type="email" required className="package-page__pricing-form__input" />
                    </div>
                    <div className="package-page__pricing-form__group">
                      <label>Phone *</label>
                      <input placeholder="Phone *" name="phone" required className="package-page__pricing-form__input" />
                    </div>
                    <div className="package-page__pricing-form__group">
                      <label>Approx number of passengers *</label>
                      <input placeholder="Approx number of passengers *" name="num_passengers" required className="package-page__pricing-form__input" />
                    </div>
                    <input type="hidden" name="form-name" value="pricing-complete-package" />
                    <p>
                      <button className="btn" type="submit">Request details and pricing</button>
                    </p>
                  </form>
                </div>

                <div className="package-page__faq">
                  <h3>FAQ</h3>
                  <div className='package__faq'>

                    <div className='package__faq-column'>

                      <div className={`${this.state.activeFaq === 'faq1' ? 'active' : ''} package__faq__item`}>
                        <div className="package__faq__head" onClick={() => { this.showFaq('faq1')}}>
                          <span>Q: How long does it take to get set up?</span>
                          <span className="package__faq__head-symbol">
                            {this.state.activeFaq === 'faq1' && (<>-</>)}
                            {this.state.activeFaq !== 'faq1' && (<>+</>)}
                          </span>
                        </div>
                        <div className="package__faq__body">
                          <p>Setup time can take as little as one day for being able to sell tickets online.</p>
                          <p>Ordering and setting up your devices for ticket scanning is typically turned around within one week.</p>
                        </div>
                      </div>

                      <div className={`${this.state.activeFaq === 'faq2' ? 'active' : ''} package__faq__item`}>
                        <div className="package__faq__head" onClick={() => { this.showFaq('faq2')}}>
                          <span>Q: What about passengers that don't have a smartphone?</span>
                          <span className="package__faq__head-symbol">
                            {this.state.activeFaq === 'faq2' && (<>-</>)}
                            {this.state.activeFaq !== 'faq2' && (<>+</>)}
                          </span>
                        </div>
                        <div className="package__faq__body">
                          <p>We understand that not every child has a smartphone. ShuttleID is designed to work as both a printed ticket and mobile ticket.</p>
                          <p>This means that passengers without a smartphone can print out their own paper pass instead.</p>
                          <p>Ticket scanning ensures that passes can't be shared. The barcode on the paper ticket can withstand up to 30% damage.</p>
                          <p>If a paper ticket is severely damaged or lost, passengers, parents or schools can just print out another one, without having to bother you or incurring a frustrating pass-replacement fee.</p>
                        </div>
                      </div>

                      <div className={`${this.state.activeFaq === 'faq3' ? 'active' : ''} package__faq__item`}>
                        <div className="package__faq__head" onClick={() => { this.showFaq('faq3')}}>
                          <span>Q: How does it work with bursaries?</span>
                          <span className="package__faq__head-symbol">
                            {this.state.activeFaq === 'faq3' && (<>-</>)}
                            {this.state.activeFaq !== 'faq3' && (<>+</>)}
                          </span>
                        </div>
                        <div className="package__faq__body">
                          <p>The Complete Package has a number of ways to handle bursaries. Voucher codes are typically the best way to process discount.</p>
                          <p>Voucher codes can be created within the Operations Portal. You can issue a voucher code to parents for use at the checkout and the discount will be applied.</p>
                          <p>This is just one of many ways to handle bursaries. Speak to one of our specialists who will be able to help you determine the best way of handling your use case.</p>
                        </div>
                      </div>

                    </div>

                    <div className='package__faq-column'>

                      <div className={`${this.state.activeFaq === 'faq4' ? 'active' : ''} package__faq__item`}>
                        <div className="package__faq__head" onClick={() => { this.showFaq('faq4')}}>
                          <span>Q: Can it handle splitting the cost between parents?</span>
                          <span className="package__faq__head-symbol">
                            {this.state.activeFaq === 'faq4' && (<>-</>)}
                            {this.state.activeFaq !== 'faq4' && (<>+</>)}
                          </span>
                        </div>
                        <div className="package__faq__body">
                          <p>Our Voucher Portal allows third parties to purchase voucher codes where the monetary value goes direct to you.</p>
                          <p>The purchaser of the voucher code (e.g., dad) can then share this with the user buying the ticket (e.g., mum), effectively splitting the cost of the ticket between multiple parties.</p>
                        </div>
                      </div>

                      <div className={`${this.state.activeFaq === 'faq5' ? 'active' : ''} package__faq__item`}>
                        <div className="package__faq__head" onClick={() => { this.showFaq('faq5')}}>
                          <span>Q: Does it support entitled travellers?</span>
                          <span className="package__faq__head-symbol">
                            {this.state.activeFaq === 'faq5' && (<>-</>)}
                            {this.state.activeFaq !== 'faq5' && (<>+</>)}
                          </span>
                        </div>
                        <div className="package__faq__body">
                          <p>We have a number of ways to support entitled travellers (funded by your local authority) within the system.</p>
                          <p>Contact us directly to find out more.</p>
                        </div>
                      </div>

                      <div className={`${this.state.activeFaq === 'faq6' ? 'active' : ''} package__faq__item`}>
                        <div className="package__faq__head" onClick={() => { this.showFaq('faq6')}}>
                          <span>Q: How long does it take for payments to be received?</span>
                          <span className="package__faq__head-symbol">
                            {this.state.activeFaq === 'faq6' && (<>-</>)}
                            {this.state.activeFaq !== 'faq6' && (<>+</>)}
                          </span>
                        </div>
                        <div className="package__faq__body">
                          <p>For tickets purchased via direct debit, scheduled monthly payments normally take two working days from the customer being charged, to you receiving the money in your bank account.</p>
                          <p>For card payments, customers are charged immediately, and the payout is received by you seven calendar days afterwards. After a short period of time, faster payouts can be enabled, which means monies will be received by you only three days afterwards.</p>
                          <p>Because the payment process is <strong>direct between the customer and your payment provider account</strong>, you receive your money faster, and the risk and inconvenience of a third party holding onto your funds is removed.</p>
                        </div>
                      </div>

                    </div>

                  </div>
                </div>


              </div>


              <Contact />
            </div>
        </div>
        <Footer />
      </Layout>
    );
  }
};

export default IndexPage
